/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

// /* Basic CSS for apps built with Ionic */
// @import '~@ionic/angular/css/normalize.css';
// @import '~@ionic/angular/css/structure.css';
// @import '~@ionic/angular/css/typography.css';
// @import '~@ionic/angular/css/display.css';

// /* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Custom */
@mixin modal-wrapper {
  margin: 1rem;
  position: relative;
  box-shadow: 0px 1px 150px rgba(0, 0, 0, 0.45);
  border-radius: 8px;
  --width: 450px;
  --max-width: 90%;
}

.modal-container .modal-wrapper {
  @include modal-wrapper;
  height: 550px;
}

.modal-change-pin .modal-wrapper {
  @include modal-wrapper;
  height: 350px;
}

.modal-balances .modal-wrapper {
  @include modal-wrapper;
  height: 300px;
  width: 300px;
}

.modal-download-report .modal-wrapper {
  @include modal-wrapper;
  height: 350px;
}

.modal-unload-card .modal-wrapper {
  @include modal-wrapper;
  height: 310px;
}

.modal-fees .modal-wrapper {
  @include modal-wrapper;
  height: 460px;
}

.ng-select-topup.ng-dropdown-panel {
  z-index: 999999;
}

.modal-card::part(content) {
  --height: 343px;
  --width: 450px;
  --background: rgba(0, 0, 0, 0);
  box-shadow: none !important;
}

.modal-card {
  .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 21px;
    right: 10px;
    border-radius: 50%;
    color: white;
    box-shadow: 0px 1px 150px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    z-index: 1;
  }

  .modal-card-body {
    position: relative;
    top: 40px;
    margin: 0 auto;
    color: #fff;
    box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    padding: 14px;
    width: 90%;
    height: 255px;

    .card-text-heading {
      font-size: 1.2rem;
    }
    .card-text {
      color: #ffffff !important;
      text-shadow:
        0 0 55px rgb(8 8 8 / 40%),
        0 1px 0 rgba(8 8 8 / 80%) !important;
    }
    .card-number {
      font-size: 1.6rem;
    }
    .card-details {
      font-size: 1.5rem;
    }
    .card-label {
      font-size: 0.65rem;
    }
    .card-pay-logo {
      width: 30px;
      height: auto;
    }
    .limited-text-wrapper {
      position: absolute;
      bottom: 14px;
      width: 94%;
    }
    .card-text-limited {
      font-size: 1.1rem;
    }

    @media (max-width: 375px) {
      padding: 12px;
      height: 215px;

      .card-text-heading {
        font-size: 1rem;
      }
      .card-number {
        font-size: 1.4rem;
      }
      .card-details {
        font-size: 1.3rem;
      }
      .card-label {
        font-size: 0.45rem;
      }
      .card-pay-logo {
        width: 30px;
        height: auto;
      }
      .card-text-limited {
        font-size: 0.9rem;
        margin-top: 12px;
      }
    }

    @media (min-width: 376px) and (max-width: 415px) {
      padding: 12px;
      height: 235px;
    }
  }

  .wave {
    height: 255px;
    width: 90%;
    position: absolute;
    background: #307be6;
    z-index: -1;
    top: 40px;
    left: 5%;
    overflow: hidden;
    border-radius: 8px;

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: linear-gradient(to bottom, rgba(85, 150, 241, 0.9) 0%, rgba(74, 142, 240, 0.3) 100%);
      border-radius: 50% 50%;
    }

    &:after {
      height: 100%;
      width: 100%;
      left: 30%;
      top: 20%;
      opacity: 0.8;
    }

    &:before {
      height: 100%;
      width: 100%;
      left: -5%;
      top: -70%;
    }

    @media (max-width: 375px) {
      height: 215px;
    }
  }
}

.card-details-loader {
  width: 90%;
  height: 255px;
  border-radius: 8px;
  background-color: rgba(165, 165, 165, 0.7);
}
